import React, { useEffect } from "react";
import { FLORA_SHOPIFY } from "../../assets/Links";

const Hello = () => {
  useEffect(() => {
    window.location.replace(`${FLORA_SHOPIFY}/privacy`);
  }, []);

  return <div></div>;
};

export default Hello;